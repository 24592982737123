import * as Types from "../types";
import clienteAxios from "../config/axios";
import { loadingRequest } from "./axiosActions";
import Swal from "sweetalert2";
import { eliminarRegistroFiltroAction } from "./filtroActions";
import moment from "moment-timezone";

export function getAgendaAction(params) {
  return async (dispatch) => {
    dispatch(getAgenda());
    try {
      const response = await clienteAxios.get("agenda/list", {
        params: params,
      });
      if (response.data.data) {
        const agendas = response.data.data.map(function (agenda) {
          const date = new Date(Date.parse(agenda.fecha));

          const start = moment(
            date.getTime() + date.getTimezoneOffset() * 60000
          );

          let end = moment(
            date.getTime() + date.getTimezoneOffset() * 60000
          ).add(30, "minute");

          if (agenda.hora_fin) {
            const dateString = date.toLocaleDateString("es-MX", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });

            end = moment.utc(
              dateString + " " + agenda.hora_fin,
              "DD/MM/YYYY hh:mm A"
            );
          }
          return {
            id: agenda.id,
            title: agenda.cliente?.nombre_completo ?? "CLIENTE NO ENCONTRADO",
            titulo_agenda: agenda.titulo,
            calendarId: agenda.id_empleado,
            subtitle: agenda.nota ?? "",
            start: start.format("YYYY-MM-DD HH:mm"),
            end: end.format("YYYY-MM-DD HH:mm"),
            hora_inicio: start.format("hh:mm A"),
            hora_fin: agenda.hora_fin ? agenda.hora_fin : end.format("hh:mm A"),
            cliente: agenda.cliente?.nombre_completo ?? "CLIENTE NO ENCONTRADO",
            telefono: agenda.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO",
            confirmado: agenda.confirmado,
            asistio_cliente: agenda.asistio_cliente,
            row: agenda,
          };
        });
        dispatch(getAgendaExito(agendas));
      }
    } catch (error) {
      console.log(error);
      dispatch(getAgendaError(error));
    }
  };
}

const getAgenda = () => ({
  type: Types.LISTA_AGENDA,
});

const getAgendaExito = (agenda) => ({
  type: Types.LISTA_AGENDA_EXITO,
  payload: agenda,
});

const getAgendaError = (error) => ({
  type: Types.LISTA_AGENDA_ERROR,
  payload: error,
});

export function agregarAgendaAction(parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      const response = await clienteAxios.post("agenda/agregar", parameters);
      if (response.data.data) {
        const data = response.data.data;
        const date = new Date(Date.parse(response.data.data.fecha));

        const start = moment(date.getTime() + date.getTimezoneOffset() * 60000);

        let end = moment(date.getTime() + date.getTimezoneOffset() * 60000).add(
          30,
          "minute"
        );

        if (data.hora_fin) {
          const dateString = date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          end = moment.utc(
            dateString + " " + data.hora_fin,
            "DD/MM/YYYY hh:mm A"
          );
        }

        const agenda = {
          id: response.data.data.id,
          title:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          titulo_agenda: response.data.data.titulo,
          calendarId: response.data.data.id_empleado,
          subtitle: response.data.data.nota ?? "",
          start: start.format("YYYY-MM-DD HH:mm"),
          end: end.format("YYYY-MM-DD HH:mm"),
          hora_inicio: start.format("hh:mm A"),
          hora_fin: response.data.data.hora_fin
            ? response.data.data.hora_fin
            : end.format("hh:mm A"),
          cliente:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          telefono:
            response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO",
          confirmado: response.data.data.confirmado,
          asistio_cliente: response.data.data.asistio_cliente,
          row: response.data.data,
        };

        dispatch(agregarAgendaExito(agenda));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(agregarAgendaError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const agregarAgendaExito = (agenda) => ({
  type: Types.AGREGAR_AGENDA_EXITO,
  payload: agenda,
});

const agregarAgendaError = (error) => ({
  type: Types.AGREGAR_AGENDA_ERROR,
  payload: error,
});

export function actualizarAgendaAction(id_agenda, parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      const response = await clienteAxios.put(
        "agenda/actualizar/" + id_agenda,
        parameters
      );
      if (response.data.data) {
        const data = response.data.data;
        const date = new Date(Date.parse(response.data.data.fecha));

        const start = moment(date.getTime() + date.getTimezoneOffset() * 60000);

        let end = moment(date.getTime() + date.getTimezoneOffset() * 60000).add(
          30,
          "minute"
        );

        if (data.hora_fin) {
          const dateString = date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          end = moment.utc(
            dateString + " " + data.hora_fin,
            "DD/MM/YYYY hh:mm A"
          );
        }

        const agenda = {
          id: response.data.data.id,
          title:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          titulo_agenda: response.data.data.titulo,
          calendarId: response.data.data.id_empleado,
          subtitle: response.data.data.nota ?? "",
          start: start.format("YYYY-MM-DD HH:mm"),
          end: end.format("YYYY-MM-DD HH:mm"),
          hora_inicio: start.format("hh:mm A"),
          hora_fin: response.data.data.hora_fin
            ? response.data.data.hora_fin
            : end.format("hh:mm A"),
          cliente:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          telefono:
            response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO",
          confirmado: response.data.data.confirmado,
          asistio_cliente: response.data.data.asistio_cliente,
          row: response.data.data,
        };
        dispatch(actualizarAgendaExito(agenda));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(actualizarAgendaError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const actualizarAgendaExito = (agenda) => ({
  type: Types.ACTUALIZAR_AGENDA_EXITO,
  payload: agenda,
});

const actualizarAgendaError = (error) => ({
  type: Types.ACTUALIZAR_AGENDA_ERROR,
  payload: error,
});

export function eliminarAgendaAction(agenda) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.delete(
        "agenda/eliminar/" + agenda.id
      );
      if (response.data.data) {
        dispatch(eliminarAgendaExito(agenda));
        dispatch(eliminarRegistroFiltroAction(agenda.id));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(eliminarAgendaError(error));
    }
  };
}

const eliminarAgendaExito = (agenda) => ({
  type: Types.ELIMINAR_AGENDA_EXITO,
  payload: agenda,
});

const eliminarAgendaError = (error) => ({
  type: Types.ELIMINAR_AGENDA_ERROR,
  payload: error,
});

export function getHorariosDisponiblesAction(id_empleado, fecha) {
  return async (dispatch) => {
    dispatch(getHorariosDisponibles());
    try {
      const response = await clienteAxios.get(
        "agenda/horarios-disponibles/" + id_empleado + "/" + fecha
      );
      if (response.data.data) {
        dispatch(getHorariosDisponiblesExito(response.data.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(getHorariosDisponiblesError(error));
    }
  };
}

const getHorariosDisponibles = () => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES,
});

const getHorariosDisponiblesExito = (horarios) => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES_EXITO,
  payload: horarios,
});

const getHorariosDisponiblesError = (error) => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES_ERROR,
  payload: error,
});

export function confirmarCitaAction(agenda) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.put("agenda/confirmar/" + agenda.id);
      if (response.data.data) {
        const data = response.data.data;
        const date = new Date(Date.parse(response.data.data.fecha));

        const start = moment(date.getTime() + date.getTimezoneOffset() * 60000);

        let end = moment(date.getTime() + date.getTimezoneOffset() * 60000).add(
          30,
          "minute"
        );

        if (data.hora_fin) {
          const dateString = date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          end = moment.utc(
            dateString + " " + data.hora_fin,
            "DD/MM/YYYY hh:mm A"
          );
        }

        const agenda = {
          id: response.data.data.id,
          title:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          titulo_agenda: response.data.data.titulo,
          calendarId: response.data.data.id_empleado,
          subtitle: response.data.data.nota ?? "",
          start: start.format("YYYY-MM-DD HH:mm"),
          end: end.format("YYYY-MM-DD HH:mm"),
          hora_inicio: start.format("hh:mm A"),
          hora_fin: response.data.data.hora_fin
            ? response.data.data.hora_fin
            : end.format("hh:mm A"),
          cliente:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          telefono:
            response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO",
          confirmado: response.data.data.confirmado,
          asistio_cliente: response.data.data.asistio_cliente,
          row: response.data.data,
        };
        dispatch(confirmarCitaExito(agenda));
        Swal.fire("¡Muy bien!", response.data.message, "success");
      }
    } catch (error) {
      dispatch(confirmarCitaError(error));
    }
  };
}

export function confirmarAsistenciaAction(agenda) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.put("agenda/confirmar-asistencia/" + agenda.id);
      if (response.data.data) {
        const data = response.data.data;
        const date = new Date(Date.parse(response.data.data.fecha));

        const start = moment(date.getTime() + date.getTimezoneOffset() * 60000);

        let end = moment(date.getTime() + date.getTimezoneOffset() * 60000).add(
          30,
          "minute"
        );

        if (data.hora_fin) {
          const dateString = date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          end = moment.utc(
            dateString + " " + data.hora_fin,
            "DD/MM/YYYY hh:mm A"
          );
        }

        const agenda = {
          id: response.data.data.id,
          title:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          titulo_agenda: response.data.data.titulo,
          calendarId: response.data.data.id_empleado,
          subtitle: response.data.data.nota ?? "",
          start: start.format("YYYY-MM-DD HH:mm"),
          end: end.format("YYYY-MM-DD HH:mm"),
          hora_inicio: start.format("hh:mm A"),
          hora_fin: response.data.data.hora_fin
            ? response.data.data.hora_fin
            : end.format("hh:mm A"),
          cliente:
            response.data.data.cliente?.nombre_completo ??
            "CLIENTE NO ENCONTRADO",
          telefono:
            response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO",
          confirmado: response.data.data.confirmado,
          asistio_cliente: response.data.data.asistio_cliente,
          row: response.data.data,
        };
        dispatch(confirmarCitaExito(agenda));
        Swal.fire("¡Muy bien!", response.data.message, "success");
      }
    } catch (error) {
      dispatch(confirmarCitaError(error));
    }
  };
}

const confirmarCitaExito = (agenda) => ({
  type: Types.CONFIRMAR_CITA_EXITO,
  payload: agenda,
});

const confirmarCitaError = (error) => ({
  type: Types.CONFIRMAR_CITA_ERROR,
  payload: error,
});



const confirmarAsistenciaExito = (agenda) => ({
  type: Types.CONFIRMAR_ASISTENCIA_EXITO,
  payload: agenda,
});

const confirmarAsistenciaError = (error) => ({
  type: Types.CONFIRMAR_ASISTENCIA_ERROR,
  payload: error,
});
