import * as Types from '../types';

import clienteAxios from '../config/axios';

export function obtenerSucursalesAction() {
    return async (dispatch) => {
        dispatch( listaSucursales() );

        try {
            const response = await clienteAxios.get('sucursales');
            if (response.data.data) {
                dispatch( listaSucursalesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( listaSucursalesError(error) );
        }
    }
}

const listaSucursales = () => ({
    type: Types.LISTA_SUCURSALES
})

const listaSucursalesExito = detalles => ({
    type: Types.LISTA_SUCURSALES_EXITO,
    payload: detalles
})

const listaSucursalesError = error => ({
    type: Types.LISTA_SUCURSALES_ERROR,
    payload: error
})