import { combineReducers } from 'redux';
import authReducer from './authReducer';
import usuarioReducer from './usuarioReducer';
import rolesReducer from './rolesReducer';
import usuarioRolesReducer from './usuarioRolesReducer';
import modulosReducer from './modulosReducer';
import menuReducer from './menuReducer';
import menuFuncionesReducer from './menuFuncionesReducer';
import rolesMenusReducer from './rolesMenusReducer';
import pacientesReducer from './pacientesReducer';
import tratamientosReducer from './tratamientosReducer';
import tratamientosPreciosReducer from './tratamientosPreciosReducer';
import empleadosReducer from './empleadosReducer';
import agendaReducer from './agendaReducer';
import ventaReducer from './ventaReducer';
import cajaReducer from './cajaReducer';
import cajaMovimientosReducer from './cajaMovimientosReducer';
import productosReducer from './productosReducer';
import unidadesMedicionReducer from './unidadesMedicionReducer';
import categoriasProductosReducer from './categoriasProductosReducer';
import inventarioReducer from './inventarioReducer';
import abonosReducer from './abonosReducer';
import filtroReducer from './filtroReducer';
import estadisticasReducer from './estadisticasReducer';
import preciosProductosReducer from './preciosProductosReducer';
import configuracionReducer from './configuracionReducer';
import axiosReducer from './axiosReducer';
import notificacionesReducer from './notificacionesReducer';
import kardexReducer from './kardexReducer';
import impuestosReducer from './impuestosReducer';
import membresiasReducer from './membresiasReducer';
import DatosAdicionalesReducer from './DatosAdicionalesReducer';
import datosAdicionalesDetallesReducer from './datosAdicionalesDetallesReducer';
import accionesReducer from './accionesReducer';
import accionesRolesReducer from './accionesRolesReducer';
import sucursalesReducer from './sucursalesReducer';

export default combineReducers({
    auth: authReducer,
    usuario: usuarioReducer,
    usuarioRoles: usuarioRolesReducer,
    roles: rolesReducer,
    modulos: modulosReducer,
    menu: menuReducer,
    menuFunciones: menuFuncionesReducer,
    rolesMenus: rolesMenusReducer,
    pacientes: pacientesReducer,
    tratamientos: tratamientosReducer,
    tratamientosPrecios: tratamientosPreciosReducer,
    empleados: empleadosReducer,
    agenda: agendaReducer,
    venta: ventaReducer,
    caja: cajaReducer,
    cajaMovimientos: cajaMovimientosReducer,
    productos: productosReducer,
    unidadesMedicion: unidadesMedicionReducer,
    categoriasProductos: categoriasProductosReducer,
    inventario: inventarioReducer,
    abonos: abonosReducer,
    filtro: filtroReducer,
    estadisticas: estadisticasReducer,
    preciosProductos: preciosProductosReducer,
    configuracion: configuracionReducer,
    axios: axiosReducer,
    notificaciones: notificacionesReducer,
    kardex: kardexReducer,
    impuestos: impuestosReducer,
    membresias: membresiasReducer,
    datosAdicionales: DatosAdicionalesReducer,
    datosAdicionalesDetalles: datosAdicionalesDetallesReducer,
    acciones: accionesReducer,
    accionesRoles: accionesRolesReducer,
    sucursales: sucursalesReducer
});