import * as Types from '../types';

const initialState = {
    sucursales: [],
    loadingSucursales: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_SUCURSALES:
            return {
                ...state,
                loadingSucursales: true
        }
        case Types.LISTA_SUCURSALES_EXITO:
            return {
                ...state,
                loadingSucursales: false,
                sucursales: action.payload
        }
        case Types.LISTA_SUCURSALES_ERROR:
            return {
                ...state,
                loadingSucursales: false,
                sucursales: []
        }
        default:
            return state;
    }
}