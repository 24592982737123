import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';
import {
  showModalEmpleadoAction,
  agregarEmpleadoProductoVentaAction,
} from "../../actions/ventaActions";

export default (props) => {
  const dispatch = useDispatch();

  const { empleados } = useSelector((state) => state.empleados);
  const { showModalVentaEmpleados, tratamientoSeleccionado } = useSelector(
    (state) => state.venta
  );

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.nombre + " " + record.apellido_paterno;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-success btn-sm"
              onClick={() => seleccionar(record)}
              style={{ marginRight: "5px" }}
            >
              Seleccionar
            </button>
          </Fragment>
        );
      },
    },
  ];

  const seleccionar = (record) => {
    tratamientoSeleccionado.id_empleado = record.id;
    tratamientoSeleccionado.nombre_empleado = record.nombre + " " + record.apellido_paterno;
    
    dispatch(agregarEmpleadoProductoVentaAction(tratamientoSeleccionado));
    dispatch(showModalEmpleadoAction(false));
  };

  const handleClose = () => {
    dispatch(showModalEmpleadoAction(false));
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModalVentaEmpleados}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Seleccionar comisionista</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable hover records={empleados.filter(empleado => empleado.comision > 0)} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
